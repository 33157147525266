$theme-colors: (
// //  "primary": #35539A,
   "primary": #5875BE,
   "secondary": #3F3255,
   "orange" : #D28856,
//   "danger": #ff4136
);

@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import "~bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Bree+Serif&display=swap');

.title{
  //font-family: 'Bree Serif', sans-serif !important;
  font-family: 'Karla', sans-serif;

}
p, h1, h2, h3, h4, h5, h6{
  font-family: 'Karla', sans-serif;
}

.bg-opac-secondary{
  background-color: #3F3255A0;
}

.text-secondary-opac{
  color: #3F3255A0;
}

.rounded-button{
  border-radius:50px !important;
}

.bg-accueil{
  background-image: url('/main_background.svg');
  background-size:cover;
  background-position: bottom;
}

.bg-cadre{
  background-image: url('/cadre.svg');
  background-size:cover;
  background-position: left;
}

.bg-hashtag1{
  background-image: url('/bg-hashtag.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-ex{
  background-image: url('/bg_ex_mobile.svg');
  background-size:cover;
  background-position: top;
  @media (max-width: 768px) {
    background-image: url('/bg_ex_mobile.svg');
    background-position: top;
  }
}

.bg-round{
  background-image: url('/bg-round.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
  height:80%;
  p{
    font-size:1.2rem;
  }
  @media (max-width: 868px) {
    background-image: url('/bg-round-mobile.svg');
    height:90%;
    p{
      font-size:1rem;
    }
  }

  @media (max-width: 668px) {
    background-image: url('/bg-round-mobile.svg');
    height:90%;
    p{
      font-size:1rem;
    }
  }
  @media (max-width: 400px) {
    background-image: url('/bg-round-mobile.svg');
    p{
      font-size:0.7rem;
    }
  }
}

.footer ul{
  @media (max-width: 768px) {
    list-style-type: none;
    padding: 0;
    li{
      text-align: center;
    }
  }
}

.navbar-light-colour{
  background-color:transparent !important;
  @media (max-width: 768px) {
    background-color: rgba(210, 136, 86,0.5) !important;
  }
}

.navbar li{
  @media (max-width: 768px) {
    text-align: right;
    a{
      color:white !important;
      font-size:1.3rem;
    }
  }
}

.bg_acc_ex{
  margin-top:-200px;
  @media (max-width: 768px) {
    margin-top:-50px;
  }
}

.bg-round1{
  background-image: url('/bg_round1.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;

}

.bg-round2{
  background-image: url('/bg_round2.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-round3{
  background-image: url('/bg_round3.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-round4{
  background-image: url('/bg_round4.svg');
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-pension{
  background-image: url('/bg-pension.svg');
  background-size:contain;
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 500px) {
    background-image: url('/bg-pension-mobile.svg');
  }
}

.bg-tarifs{
  background-image: url('/bg-tarifs.svg');
  background-size:cover;
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 500px) {
    background-image: url('/bg-tarifs.svg');
  }
}

.d-perso-none{
  display: block;
  @media (max-width: 600px) {
    display:none;
  }
}

.d-perso-block{
  display: none;
  @media (max-width: 600px) {
    display:block;
  }
}

#demo{
  font-size:5.5rem;
  @media (max-width: 600px) {
    font-size:4rem;
  }
  @media (max-width: 400px) {
    font-size:2.5rem;
  }
}

.w-md-100{
  @media (min-width: 768px) {
    width:100% !important;
    border :1px solid white;
  }
}

.bg-card{
  background-image: url('/bg-card.svg');
  background-size:contain;
  background-position: top;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: .5s ease-in-out forwards;
}
.bg-card:hover{
  transition: background-size 2s ease-in 1 forwards;
-moz-transition: background-size 2s ease-in 1 forwards;
-ms-transition: background-size 2s ease-in 1 forwards;
-o-transition: background-size 2s ease-in 1 forwards;
-webkit-transition: background-size 2s ease-in 1 forwards;
animation: background-size 1s 0s ease-in-out 1 forwards;

}

@keyframes background-size {
  100% {
    background-color:rgba(198, 194, 204,1);
  }
}

.background-clear{
  background-color: #e0dfe3A0;
}

.footer li a:hover, .navbar li:hover {
  background-position: 0 0;
  background: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(210, 136, 86,0.5) 50%);
  background-size: 200%;
  display: inline;
  animation: highlight-text 1s 0s ease 1 forwards;
  text-decoration: none;
  a{
    text-decoration: none;
  }
}


.entry-title{
  background: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(210, 136, 86,0.5) 50%);
  background-size: 200%;
  background-position: 0 0;
  display: inline;
  transition: .5s ease-in-out;
  animation: highlight-text 3s 0.5s ease 1 forwards;
}


.entry-title-other{
  background: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(104, 114, 178,0.4) 50%);
  background-size: 200%;
  background-position: 0 0;
  display: inline;
  transition: .5s ease-in-out;
  animation: highlight-text 3s 0.5s ease 1 forwards;
  z-index:-9999;
}

@keyframes highlight-text {
  100% {
    background-position: -100% 0;
  }
}

.position_underline{
  min-width:180px;
  min-height:15px;
  opacity:0.4;
  z-index:-90;
  position:absolute;
  margin-top:140px;
  margin-left:30px;
  @media (min-width: 768px) {
    margin-top:90px;
    margin-left:390px;
  }
}

.ml-30{
  margin-left:0px;
  @media (min-width: 768px) {
    margin-left:30px;
  }
}


.progress{
  -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}



$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $primary;
}

.checkmark_animate{
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark_animate_false{
	animation: fillFalse .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $primary;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

.checkmark__check_false {

  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 10;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #44B4AB;
  }
}

@keyframes fillFalse {
  100% {
    box-shadow: inset 0px 0px 0px 30px red;
  }
}


.height-perso-pension{
  min-height: 100vh;
  @media (max-width: 608px) {
    min-height: 10vh !important;
  }
  @media (max-width: 830px) {
    min-height: 50vh !important;
  }
  @media (max-width: 990px) {
    min-height: 60vh !important;
  }
}

.corner{
  width:440px;
  @media (max-width: 508px) {
    width:310px;
  }
}

.resize-staff{
  font-size:4rem;
  @media (max-width: 608px) {
    font-size:2rem;
  }
}


.opac{
  opacity: 0.4 !important;
}


.disabled-onglet{
  margin-top:auto;
  opacity:0.6;
}

.enable-choice{
  font-size:2rem;
}


.gradient{
  filter: blur(4px);
  -webkit-filter: blur(4px);
  @media (max-width: 1158px) {
    min-height:40vh;
  }
  @media (max-width: 808px) {
    min-height:40vh !important;
    max-height:60vh !important;
    width:auto !important;
  }
  @media (max-width: 408px) {
    min-height:75vh !important;
    max-height:75vh !important;
    width:auto !important;
  }
}

.margin-right-media{

  @media (max-width: 1008px) {
    margin-right:auto !important;
  }

}


.margin-perso{
  @media (max-width: 808px) {
    margin-top:10vh;
  }
  @media (max-height: 600px) {
    margin-top:20vh;
  }
}


.mt-perso{
  margin-top:50%;
  @media (max-width: 808px) {
    margin-top:110%;
  }
}

.try-bounce{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  transform: rotate(90deg);
}

@keyframes bounceAlpha {
  //0% {opacity: 0.3; transform: translateY(0px) scale(0.4);}
  //25%{opacity: 1; transform:translateY(+10px) scale(0.2);}
  56%{opacity: 0.3; transform:rotate(90deg) scale(1);}
  // 55% {opacity: 1; transform: translateY(+10px) scale(1);}
  // 80%{opacity: 0.3; transform:translateY(-10px) scale(0.9);}
  // 90%{opacity: 1; transform:translateY(+10px) scale(0.9);}
  // 100% {opacity: 0.3; transform: translateY(-10px) scale(1);}
}



.bar-color{
  background: rgb(98,195,231);
  background: linear-gradient(120deg, rgba(98,195,231,0.6376925770308124) 0%, rgba(63,50,85,0.30996148459383754) 0%, rgba(63,50,85,1) 0%, rgba(63,50,85,1) 68%, rgba(255,0,89,0.35477941176470584) 100%);
  max-height:10px;
}
